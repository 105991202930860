@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  background-color: #eef1f5;
}

a {
  color: #3f51b5;
}

a:hover {
  text-decoration: none;
}

header a {
  text-decoration: none;
}

a:visited {
  color: #000;
}

#header #logo {
  flex-grow: 0.1;
}

#header .emoji {
  height: 1.5em;
  vertical-align: middle;
  padding-right: 0.25em;
}

#header .TableCount {
  padding: 1em;
  font-size: 0.8125rem;
}

#header .GitHub[type="button"] {
  margin-left: 0.7125em;
}

#header .GitHub img {
  height: 1.25em;
}

.GitHub.MuiIconButton-sizeSmall {
  padding: 0px;
}

body, h1, h2, h3, h4, p {
  font-family: 'Roboto', sans-serif;
}


/* CUSTOM */

.filtro-tel {
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  justify-content: center;
  /* gap: 20px; */
}
.parte {
  display: flex;
  align-items: center;
}
.parte.filtro {
  gap: 10px;
}

.list-cat {
  width: max-content;
}

.alignElementCenter {
  display: flex;
  margin: auto;
}
/* CSS for the border container and label */
.borderContainer {
  border: 2px solid #ccc; /* Customize the border style, color, and width */
  padding: 10px; /* Add padding to create space between the border and content */
  display: inline-block; /* Display as inline-block to fit content width */
}

.borderLabel {
  font-weight: bold; /* Make the label bold if needed */
  margin-bottom: 5px; /* Add spacing between the label and content */
}


.MuiRating-root > label{
  padding-right: 4px;
  padding-left: 4px;
}

@import url("https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css");
.leaflet-control-zoom{
  opacity: 0.5;
}
.leaflet-bottom.leaflet-left{
  opacity: 0.7;
}
.leaflet-popup-content{
  width: 80%;
}
.ods{
  width: 100px;
  height: 100px;
}
.directionIcon{
  width: 18px;
  height: 18px;
}
.floatRight{
  float: right;
}
.buttonsSidebySide{ 
  text-align: center;
  margin: 0 auto;
  display: inline;
}
.nLocal{
  width: 54px;
}
.relativePosition{
  position: relative;
  padding: 0 2%;
}
.toolPanel{
  background-color: transparent !important;
  box-shadow: none !important;
}
button, .SubmitButton {
  background-color: #3897f0;
  border: 1px solid #3897f0;
  padding: 5px 12px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition-duration: .4s;
}
.SubmitButton:hover {
  background-color: #2d7ac2;
}
.SubmitButton:active {
  background-color: #215b92;
  transition-duration: .2s;
}
select{
  border-radius: 4px;
}
.uxLi{  
  display: block;
  padding: 7px 0px;
  cursor: pointer;
}
.greenHub{
  background-color: #baffb8;
  border-radius: 8px;
}
.blueHub{
  background-color: #cfd7fa;
  border-radius: 8px;
}
.yellowHub{
  background-color: #ffdf9d;
  border-radius: 8px;
}
.redHub{
  background-color: #fca099;
  border-radius: 8px;
}


.leaflet-touch .leaflet-control-layers-toggle {  
  width: 28px !important;
  height: 28px !important;
}
.leaflet-control-layers{
  background: #9191b8 !important;
}
.leaflet-control-layers-toggle{
  background-image: url(https://fonts.gstatic.com/s/i/googlematerialicons/layers/v9/white-18dp/1x/gm_layers_white_18dp.png) !important
}
textarea{
  width: 95%;
  height: 100px;
}
.beta {
  font-size: 11px;
}
input[type="text"] {
  padding: 8px 12px;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 8px;  
  height: 44px;
}

input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
  margin: 8px;
}

ul {
  list-style: none;
  margin: 0 0 20px;
  /* margin-top: 20px; */
  padding: 0;
}

figure {
  background: rgb(201, 201, 201);
  height: 1px;
}
img.telegram {
  box-sizing: border-box;
  background: url(https://telegram.org/img/WidgetButton_LogoSmall.png) no-repeat 0 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
}
a.tgme_widget_share_btn {
  box-sizing: border-box;
  height: 26px;
  width: 60px;
  display: block;
  background-color: #54A9EB;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0;
  padding: 3px 0px 0px 19px;
  margin-top: 10px;
  text-decoration: none;
}

.SubmitButton{
  margin-right: 10px;
  /*margin-bottom:22px;*/
  height: 36px;
}
.SubmitButton.marcar-local {
  width: 40%;
}

.form-endereco {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 32px auto 22px;
}


.wp
    {
        width: 25px;
        margin-top: 5px;

    }
.wpbtn
    {
        border: none;
        background-color:white;
    }

/* Table Styling */

#CoffeeTable {
  display: flex;
}

.flexLoading {
  display: flex;
  height: 100%;
  margin: 0 auto;
}

.loading {
  align-self: center;
  margin: 0 auto;
  font-weight: bold;
  color: #3f51b5;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

.MuiTableCell-root {
  min-width: 125px;
}

#CoffeeTable [class$="head"] {
  text-transform: capitalize;
}

#CoffeeTable [class$="head"] th:last-child {
  text-align: right;
}

/* Map Layout Styling */

#map {
  width: 100vw !important;
}

#CoffeeMap {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.MuiPaper-elevation1 {
  height: 89vh;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiPaper-rounded {
  border-radius: 0px 0px 4px 4px !important;
}

.MuiAppBar-root {
  border-radius: 4px 4px 0px 0px;
}

/* Leaflet styling */

/* .leaflet-container a {
  color: #8a4b38 !important;
} */

.leaflet-tooltip span {
  display: block;
  text-align: left;
}

.leaflet-pane.leaflet-tooltip-pane span:first-of-type {
  font-weight: bold;
}

.leaflet-container .leaflet-control-attribution {
  opacity: 0.75;
}

/* react-leaflet-markercluster overrides for CircleMarker */

.leaflet-marker-icon.marker-cluster.marker-cluster-small.leaflet-zoom-animated.leaflet-interactive {
  font-weight: bold !important;
  color: #000 !important;
}

.marker-cluster-small-anjosCluster {
  background-color: rgb(0, 70, 102);
}

.marker-cluster-small-anjosCluster div {
  background-color: #bdd5db !important;
}

.marker-cluster-medium-anjosCluster {
  background-color: rgb(0, 50, 102) !important;
}

.marker-cluster-medium-anjosCluster div {
  background-color: #bdcadb !important;
}

.marker-cluster-large-anjosCluster {
  background-color: rgb(22, 0, 102) !important;
}

.marker-cluster-large-anjosCluster div {
  background-color: #c0bddb !important;
}


.marker-cluster-small-precisandoCluster {
  background-color: rgb(212, 162, 64);
}

.marker-cluster-small-precisandoCluster div {
  background-color: #dbdbbd !important;
}

.marker-cluster-medium-precisandoCluster {
  background-color: rgb(216, 174, 59) !important;
}

.marker-cluster-medium-precisandoCluster div {
  background-color: #dbd3bd !important;
}

.marker-cluster-large-precisandoCluster {
  background-color: rgb(163, 97, 5) !important;
}

.marker-cluster-large-precisandoCluster div {
  background-color: #dbcdbd !important;
}

.marker-cluster-small:hover, .marker-cluster-medium:hover, .marker-cluster-large:hover {
  box-shadow: inset 0 0 0 25px #003238 !important;
}

.leaflet-bean-icon {
  border: 0px !important;
  background-color: transparent !important;
}

.leaflet-marker-icon span {
  font-weight: 500;
  color: #000 !important;
}

.leaflet-marker-icon {
  opacity: 0.8 !important;
}

/* Mobile responsive breakpoints */

/* Media query for screens with a maximum height of 630px */
@media screen and (max-width: 600px) {
/* @media screen and (max-width: 1200px) {*/
  
  body{
    font-size: 1.25em;
  }

  #CoffeeMap {
    height: 60vh;
  }
  .leaflet-container {
    height: 60vh !important;
    overflow: hidden !important;
  }

  .relativePosition{
    padding: 5%;
    padding-bottom: 0;
  }
    
  .filtro-tel {
    gap: 10px;
  }
  .parte.filtro {
    gap: 8px;
  }

  .list-cat {
    width: fit-content;
  }

  #header .emoji {
    height: 1.4em;
  }
  #header h6 {
    font-size: 0.9em;
  }
  .MuiPaper-rounded {
    border-radius: 4px !important;
  }
  .MuiButton-containedSizeSmall {
    font-size: 0.7125rem !important;
  }
  #header .TableCount {
    display: none;
  }
  #header #RoasterButtonMobile {
    display: none;
  }
  #CoffeeTable {
    height: 58vh;
  }

  
}


/* Media query for screens with a height greater than 630px */
@media screen and (min-height: 631px) and (max-width: 600px) {

	#CoffeeMap {
	height: 65vh;
	}

	.leaflet-container {
	height: 65vh !important;
	overflow: hidden !important;
	}
	body{
		font-size: 1.25em;
	}

	#header .emoji {
	height: 1.4em;
	}
	#header h6 {
	font-size: 1.25em;
	}
	.MuiPaper-rounded {
	border-radius: 4px !important;
	}
	.MuiButton-containedSizeSmall {
	font-size: 0.7125rem !important;
	}
	#header .TableCount {
	display: none;
	}
	#header #RoasterButtonMobile {
	display: none;
	}
	#CoffeeTable {
	height: 58vh;
	}

}

/* fadeIn Animation */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}